@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
}
